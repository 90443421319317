import React,{Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Redirect,Switch} from 'react-router-dom'
import AuthContext from './context/auth-context';

//Pages 
import WasteReportDetailPage from './pages/WasteReportDetailPage'
import EmailResponse from './pages/emailResponse'
import AuthPage from './pages/Auth';
import MainPage from './pages/MainPage'
import Konteyners from './pages/Konteyners'
import CreateEvent from './pages/CreateEvent'
//Waste Reports Pages
import PastWasteReports from './pages/wasteReports/pastWasteReports'
import AllWasteReports from './pages/wasteReports/allWasteReports'
import ActiveWasteReports from './pages/wasteReports/activeWasteReports'
import CanceledWasteReports from './pages/wasteReports/canceledWasteReports'
import AdvancedReportQuery from './pages/wasteReports/advancedReportQuery'
import LogoutPage from './pages/LogOut'
//Events Pages
import ActiveEvents from './pages/events/activeEvents'
import PastEvents from './pages/events/pastEvents'
import AllEvents from './pages/events/allEvents'
import PdfTry from './pages/pdfTry'
//Reporting Pages
import WasteReporting from './pages/reports/wasteReporting'
import UserReporting from './pages/reports/userReporting'
import WasteReportPlace from './pages/reports/wasteReportPlace'
// Components 
import {Provider} from 'react-redux'
import {store} from './store'
//Account Pages
import CreateAccount from './pages/accounts/createAccount'
import AllAccounts from './pages/accounts/allAccounts'
import DownloadExcel from './pages/downloadExcel';

import Header from './components/header'
import Sidebar from './components/sidebar'
import Footer from './components/footer';

import RequireAuth from './components/RequireAuth';
import{setToken,setCurrentUser,addError} from './store/actions/'


if(localStorage.jwtToken)
{
    setToken(localStorage.jwtToken)
    try {
        store.dispatch(setCurrentUser(localStorage.jwtToken))
    } catch (error) {
        store.dispatch(setCurrentUser({}))
        store.dispatch(addError(error))
    }
}

class App extends Component {

  state ={
    token:null,
    _id:null,
    redirect:false
  }


  render() {
    console.log = function () {};
    console.log(this.state.token)
    return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthContext.Provider value={{token: this.state.token,
          _id:this.state._id,
          login:this.login,
          logout:this.logout}}> 
          <Header />
          <Sidebar />
          <Switch>
            <Route path="/" exact component={RequireAuth(MainPage)} ><Redirect to="/index"></Redirect></Route>
            <Route path="/auth" exact component={AuthPage} />
            <Route path="/index" exact component={RequireAuth(MainPage)} />
            <Route path="/createEvent" exact component={CreateEvent} />
            <Route path="/konteyner" exact component={Konteyners} />
            <Route path="/wasteReportDetail" exact component={WasteReportDetailPage} />
            <Route path="/logout" exact component={LogoutPage} />
            <Route path="/activeWasteReports" component={ActiveWasteReports} />
            <Route path="/pastWasteReports" component={PastWasteReports} />
            <Route path="/allWasteReports" component={AllWasteReports} />
            <Route path="/canceledWasteReports" component={CanceledWasteReports} />
            <Route path="/advancedReportQuery" component={AdvancedReportQuery} />
            <Route path="/activeEvents" component={ActiveEvents} />
            <Route path="/pastEvents" component={PastEvents} />
            <Route path="/allEvents" component={AllEvents} />
            <Route path="/pdfTry" component={PdfTry} />
            <Route path="/wasteReporting" component={WasteReporting} />
            <Route path="/userReporting" component={UserReporting} />
            <Route path="/wasteReportPlace" component={WasteReportPlace} />
            <Route path="/createAccount" component={CreateAccount} />        
            <Route path="/allAccounts" component={AllAccounts} />      
            <Route path="/emailResponse" component={EmailResponse} />
            <Route path="/downloadExcel" component={DownloadExcel} />
          </Switch>
          <Footer />
        </AuthContext.Provider>
      </BrowserRouter>
    </Provider>
    );
  }
}

export default App;
