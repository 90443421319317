//HoC for check authentication
import React, { Component } from 'react';
import { connect } from 'react-redux';

export default (ComposedComponent) => {
   class RequireAuth extends Component {
      componentWillMount() {
         console.log(this.props.auth.isAuthenticated)
       
         if (!this.props.auth.isAuthenticated)
            this.props.history.replace("/auth");
      }
      componentWillUpdate() {
         if (!this.props.auth.isAuthenticated)
            this.props.history.replace("/auth");
        
      }
      render() {
         return (
           
            <ComposedComponent { ...this.props } />
         )
      }
   }


   return connect(store=>({auth:store.auth}))(RequireAuth);
}