import React,{Component} from 'react'

import {call} from '../services/api'


class CreateEvent extends Component{

    constructor(props)
    {
        super(props);
        this.eventName  = React.createRef();
        this.description = React.createRef();
        this.date  = React.createRef();
        this.place = React.createRef();
    }


 createEvent = (event)=>{
    event.preventDefault()
    const eventName = this.eventName.current.value
    const descriptipn = this.description.current.value
    const date = new Date (this.date.current.value)
    const place = this.place.current.value

    if(eventName.trim().length === 0 || descriptipn.trim().length === 0 || date == "Invalid Date" || place.trim().length === 0)
    {
        return;
    }

    const requestBody = {
        query: `
            mutation{
                createEvent(name:"${eventName}",description:"${descriptipn}",time:"${date}",place:"${place}")
                {
                _id
                }
            }
        `}
    

    call(requestBody).then(resData=>{
           
            if(!resData.error)
            {
              this.props.history.replace('/allEvents')
            }
        }).catch(err=>{console.log(err)})

  }

    render(){
        return(
            <div className="page-wrapper">
            {/* ============================================================== */}
            {/* Container fluid  */}
            {/* ============================================================== */}
            <div className="container-fluid">
              {/* ============================================================== */}
              {/* Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">Etkinlik Oluşturma</h4>
                </div>
                
              </div>
              {/* ============================================================== */}
              {/* End Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              {/* ============================================================== */}
              {/* Start Page Content */}
              {/* ============================================================== */}
              {/* row */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Lütfen Etkinlik Bilgilerini Girin</h4>
                      
                      <form className="mt-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Etkinlik Adı</label>
                          <input type="text" className="form-control" id="etkinlik-adi" aria-describedby="emailHelp" ref={this.eventName} placeholder="Etkinlik adını yazın" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Etkinlik Açıklaması</label>
                          <input type="text" className="form-control" id="etkinlik-aciklamasi" ref={this.description} placeholder="Etkinliği tanımlayan açıklamayı yazın" required/>
                        </div>
                        
                        <div className="form-group">
                            <label>Etkinlik Tarihi</label>
                            <input type="text" id="date-format" class="form-control" ref={this.date} placeholder="Buraya tıklayın" required/>                
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Etkinlik Mekanı</label>
                          <input type="text" className="form-control" id="etkinlik-mekani" ref={this.place} placeholder="Etkinliğin gerçekleşeceği mekanı yazın" required/>
                        </div>
                        
                        <button type="submit" onClick={this.createEvent} className="btn btn-primary">Etkinlik Oluştur</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default CreateEvent