import  React, {Component}from 'react'
import {call} from '../../services/api'

class CreateAccount extends Component {

    constructor(props)
    {
        super(props);
        this.name  = React.createRef();
        this.email = React.createRef();
        this.password  = React.createRef();
     

        this.state = {
            cityId: "",
            cityName: "",
            waitCreating: false,
            statusMessage:"",
            userType: ""
        }
    }

    async componentDidMount(){
        
    }

    
    createGovUser = async (event)=>{
        event.preventDefault()
        const name = this.name.current.value
        const email = this.email.current.value
        const password = this.password.current.value
       
        
        if(name.trim().length === 0 || email.trim().length === 0 || password.trim().length === 0 )
        {
            return;
        }
    
        await this.setState({waitCreating: true})
      
        const userRequestBody = {
            query: `mutation{
              createGovUser(
                email:"${email}", password:"${password}",name:"${name}"
              ){
                _id
              }
            }`
          }
        call(userRequestBody).
            then(async resData=>{
                if(resData.error)
                {
                  await this.setState({waitCreating: false,statusMessage: "Hesap oluşturulamadı"})
                }else{
                  console.log(resData)
                  await this.setState({waitCreating: false,statusMessage: "Hesap oluşturuldu"})
                }}).catch(async err=>{console.log(err); await this.setState({waitCreating: false,statusMessage: "Hesap oluşturulamadı"})})
    
      }

    render(){
        if(this.state.waitCreating){
            return(
              <div class="page-wrapper">
              <div class="container-fluid">
              <h1>Bekleyin...</h1>
              </div>
              </div>
            )
          }
            return(
                <div className="page-wrapper">
                {/* ============================================================== */}
                {/* Container fluid  */}
                {/* ============================================================== */}
                <div className="container-fluid">
                  {/* ============================================================== */}
                  {/* Bread crumb and right sidebar toggle */}
                  {/* ============================================================== */}
                  <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                      <h4 className="text-themecolor">Kullanıcı Oluşturma</h4>
                    </div>
                    
                  </div>
                  {/* ============================================================== */}
                  {/* End Bread crumb and right sidebar toggle */}
                  {/* ============================================================== */}
                  {/* ============================================================== */}
                  {/* Start Page Content */}
                  {/* ============================================================== */}
                  {/* row */}
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Lütfen Kullanıcı Bilgilerini Girin</h4>
                            
                       {/*<form className="mt-4">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Kullanıcı Tipi</label>
                            <br></br>
                            <select class="form-control-lg" onChange={async (e) => {await this.setState({ userType: Number(e.target.value)})}}>
                                <option value={0}>Kullanıcı Tipi Seç</option>
                                <option value={1}>Panel Kullanıcısı</option>
                                <option value={2}>Uygulama Kullanıcısı</option>
                            </select>
                            </div>
                            </form>*/}
                          <form className="mt-4">
                          
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email</label>
                              <input type="email" className="form-control" id="etkinlik-aciklamasi" ref={this.email} placeholder="Çalışana verilecek hesabın email adresini yazın" required/>
                            </div>
                       
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Şifre</label>
                              <input type="password" className="form-control" id="etkinlik-mekani" ref={this.password} placeholder="Hesap şifresi" required/>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Ad Soyad</label>
                              <input type="text" className="form-control" id="etkinlik-adi" aria-describedby="emailHelp" ref={this.name} placeholder="Ad Soyad" required/>
                            </div>
                            {this.state.statusMessage === "Hesap oluşturuldu" && 
                            <h3 style={{color:"green"}}>{this.state.statusMessage}</h3>}
                            {this.state.statusMessage === "Hesap oluşturulamadı" && 
                            <h3 style={{color:"red"}}>{this.state.statusMessage}</h3>}
                            <button type="submit" onClick={this.createGovUser} className="btn btn-primary">Hesap Oluştur</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        }
    }

export default CreateAccount
