import React,{Component, } from 'react'
import {call} from '../services/api'
import queryString from 'query-string'

class EmailResponse extends Component{

    state = {
        errorMessage:"",
        success: false,
        notSuccess: false
      };
    
async componentDidMount(){
    let queryStringValue = queryString.parse(this.props.location.search)
    console.log(queryStringValue)
    const requestBody = {
        query: `
        mutation{
            changeWasteStatus(_id:"${queryStringValue._id}",status:"${queryStringValue.statusToSend}", explanation:"${queryStringValue.explanationValue}", source:"Email")
            {
                _id
            }
        }
    `
    }

    call(requestBody).then(resData=>{
            if(resData.errors) 
            {
                console.log(resData.errors)
                this.setState({notSuccess: true})  
            }else{
            console.log(resData)  
            this.setState({success: true}) 
            } 
    }).catch(err=>{console.log(err); this.setState({notSuccess: true})  
})


}

    render(){
      if(this.state.success){
        return(
            <div class="page-wrapper">
                <div class="container-fluid">
                    <h1>İşlem başarılı</h1>
                </div> 
           </div>
        )     
    }else if(this.state.notSuccess){
        return(
            <div class="page-wrapper">
                <div class="container-fluid">
                    <h1>İşlem başarısız. Lütfen belediye.kollektapp.org/auth adresinden Üsküdar Belediyesi Kollekt hesabınızla ve şifrenizle giriş yapınız.</h1>     
                </div> 
           </div>
        )     
    }
    else{
        return (
            <h1>İşlem sürüyor</h1>
        )
    }
}
}
const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '60%'
    }
  };
  
export default EmailResponse