import React,{Component} from 'react'
import AuthContext from '../context/auth-context'
import {Redirect} from 'react-router-dom';

class PdfTry extends Component{

  

    render(){
        return (
            <div className="page-wrapper">
            {/* ============================================================== */}
            {/* Container fluid  */}
            {/* ============================================================== */}
            <div className="container-fluid">
            <div className="card">
                    <div className="card-body">
            <div id="page-content">
            
            <h1>Hello, world!</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vehicula bibendum lacinia. Pellentesque placerat interdum nisl non semper. Integer ornare, nunc non varius mattis, nulla neque venenatis nibh, vitae cursus risus quam ut nulla. Aliquam erat volutpat. Aliquam erat volutpat. Etiam eu auctor risus, condimentum sodales nisi. Curabitur aliquam, lorem accumsan aliquam mattis, sem ipsum vulputate quam, at interdum nisl turpis pharetra odio. Vivamus diam eros, mattis eu dui nec, blandit pulvinar felis.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vehicula bibendum lacinia. Pellentesque placerat interdum nisl non semper. Integer ornare, nunc non varius mattis, nulla neque venenatis nibh, vitae cursus risus quam ut nulla. Aliquam erat volutpat. Aliquam erat volutpat. Etiam eu auctor risus, condimentum sodales nisi. Curabitur aliquam, lorem accumsan aliquam mattis, sem ipsum vulputate quam, at interdum nisl turpis pharetra odio. Vivamus diam eros, mattis eu dui nec, blandit pulvinar felis.</p>
            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                            <thead>
                                <tr>
                                <th>Bildirim Sayısı</th>
                                <th>Kullanıcı</th>
                                <th>Durum</th>
                                <th>Bildirim Tarihi</th>
                                <th>Görüntüle</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Bildirim Sayısı</th>
                                <th>Kullanıcı</th>
                                <th>Durum</th>
                                <th>Bildirim Tarihi</th>
                                <th>Görüntüle</th>
                                </tr>
                            </tfoot>
                            </table>

            </div>
            <a class="word-export" href="javascript:void(0)"> Export as .doc </a>
            </div>
        </div>
        </div>
        </div>
        )
    }
}

export default PdfTry