import {call} from '../../../../services/api'
import React from 'react'

export const deleteReport = (reportId, deletedRepArrayArg) => {
    return new Promise((resolve, reject) => {
        const requestBody = {
            query: `
                mutation{
                    deleteWasteReport(_id:"${reportId}")
                    {
                        _id
                    }
                }
            `
        }
    
        call(requestBody).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
                reject({deleteSuccessMessage: "", errorMessage: "Bildirim Silinemedi"})
            }
            let deletedRepArray = deletedRepArrayArg
            deletedRepArray.push(reportId)
            resolve({deleteSuccessMessage: "Bildirim Silindi", errorMessage: "", deletedReportIds: deletedRepArray})
        }).catch(err => {
            console.log(err);
            reject({deleteSuccessMessage: "", errorMessage: "Bildirim Silinemedi"})
        })
    })
}

export const isDeleted = (deletedReportIds, repId, forActive) => {
    let disable = false
        if (forActive) {
            if (deletedReportIds.length === 0) {
                disable = false
            } else {
                for (let i = 0; i < deletedReportIds.length; i++) {
                    if (repId === deletedReportIds[i]) {
                        disable = true
                        break
                    } else {
                        disable = false
                    }
                }
            }
        } else {
            if (deletedReportIds.length === 0) {
                disable = "Sil"
            } else {
                for (let i = 0; i < deletedReportIds.length; i++) {
                    if (repId === deletedReportIds[i]) {
                        disable = "Silindi"
                        break
                    } else {
                        disable = "Sil"
                    }
                }
            }
        }

        return disable
}

export const getWasteStatus = (wasteStatus, statusExplanation) => {
    if (statusExplanation === "Islem") {
        return getWasteStatusSpan("#a5b859", "İşleme alındı")
    } else if (wasteStatus === "bekliyor") {
        return getWasteStatusSpan("#ffbf3e", wasteStatus)
    } else if (wasteStatus === "Toplandı") {
        return getWasteStatusSpan("#7abe45", wasteStatus)
    } else if (wasteStatus === "İptal Edildi") {
        return getWasteStatusSpan("#fc4b6c", wasteStatus)
    }else if (wasteStatus === "Çözüm Aranıyor") {
        return getWasteStatusSpan("#fc4b6c", wasteStatus)
    }
    else if(wasteStatus === "Atıldı"){
        return getWasteStatusSpan("#7abe45", wasteStatus)
    }
}

function getWasteStatusSpan(color, text){
    return <span class="badge badge-success badge-pill" style={{ backgroundColor: color }}>{text}</span>
}
