import  React, {Component, Link}from 'react'
import {call} from '../../services/api'
import {dateConvertion} from '../helpers/dateHelpers'

class AllAccounts extends Component {

    constructor(props)
    {
        super(props);
        this.name  = React.createRef();
        this.email = React.createRef();
        this.password  = React.createRef();
     

        this.state = {
            statusMessage:"",
            userType: "",
            allUsers: [],
            dataCame: false
        }
    }

    componentDidUpdate(){
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `$('#example23').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                        }
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        if(this.state.dataCame)
        {
            document.body.appendChild(s);
        }
    }
   

    async componentDidMount(){
        const AllUsersRequest = {
            query: `query{
             getMinistryUsers{
                name
                email
                createdAt
                userType
              }
            }`
          }
        call(AllUsersRequest).
            then(async resData=>{
                if(resData.error)
                {
                
                }else{
                  console.log(resData.data.getMinistryUsers)
                  await this.setState({allUsers: resData.data.getMinistryUsers, dataCame: true})
                }})
            .catch(async err=>{console.log(err)})
      }
    render(){
        if(!this.state.dataCame){
            return(
              <div class="page-wrapper">
              <div class="container-fluid">
              <h1>Bekleyin...</h1>
              </div>
              </div>
            )
          }
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
    
                <div class="row page-titles">
                        <div class="col-md-5 align-self-center">
                            <h4 class="text-themecolor">Tüm Belediye Kullanıcıları</h4>
                        </div>
                        <div class="col-md-7 align-self-center text-right">
                            <div class="d-flex justify-content-end align-items-center">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                    <li class="breadcrumb-item active">Tüm Belediye Kullanıcıları</li>
                                </ol>
                            </div>
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="card-title">Tüm Belediye Kullanıcıları</h4>
                            <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                            <div className="table-responsive m-t-40">
                                <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                <thead>
                                    <tr>
                                    <th>Email</th>
                                    <th>Ad Soyad</th>
                                    <th>Oluşturulma Tarihi</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                    <th>Email</th>
                                    <th>Ad Soyad</th>
                                    <th>Oluşturulma Tarihi</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {this.state.allUsers.map(user => {
                                        return(
                                            <tr>
                                            {user.email !== null ? <td>{user.email}</td> : <td>kullanıcı bilgisi girilmemiş</td> }
                                            <td>{user.name}</td>
                                            <td>{dateConvertion(new Date(Number(user.createdAt)))}</td>
                                            </tr>
                                          )              
                                    })}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

export default AllAccounts
