import React,{Component, } from 'react'
import AuthContext from '../context/auth-context'
import GoogleMapReact from 'google-map-react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { NavLink, Link, Redirect } from 'react-router-dom'
import {dateConvertion} from './helpers/dateHelpers'
import {ShowKonteynerNameAccordingToType} from './helpers/markerHelpers'
import {call} from '../services/api'
import Modal from 'react-modal';
import queryString from 'query-string'

class WasteReportDetailPage extends Component{

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        latitude: null,
        longitude: null,
        report: null,
        date: null,
        returnBack: false,
        explanationValue: "",
        cancelModalBool: false,
        finishModalBool: false,
        wasteStatus: "",
        errorMessage:""
      };
    
async componentDidMount(){
    let repId = queryString.parse(this.props.location.search)
    const requestBody = {
        query: `
            query{
                getWasteReportById(_id:"${repId._id}")
                {
                    _id
                    images
                    privacy
                    wasteType
                    latitude
                    longitude
                    createdAt
                    isSelf
                    waste_status
                    createdAt
                    creator {email}
                    registeredMinistry {_id}
                }
            }
        `
    }
    call(requestBody)
    .then(async res => {
        let resData = res.data.getWasteReportById
        console.log(res)
        await this.setState({
            report: resData, 
            date: resData.createdAt,
            latitude: resData.latitude,
            longitude: resData.longitude
        }) 
    })
    .catch(err => console.log(err))
 
    

}
onMarkerClick = async (props, marker, e) =>{

    await this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
     
    });
}

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  async confirmWaste(){
    let a = this.state.report._id
    if(this.state.explanationValue === ""){
        await this.setState({errorMessage: "En az bir seçenek seçilmelidir"})
        return
    }
    let statusToSend = this.state.explanationValue === "Islem" ? "bekliyor" : "Toplandı"
    const requestBody = {
        query: `
        mutation{
            changeWasteStatus(_id:"${this.state.report._id}",status:"${statusToSend}", explanation:"${this.state.explanationValue}", registeredMinistry:"${this.state.report.registeredMinistry._id}")
            {
                _id
            }
        }
    `
    }


    call(requestBody).then(resData=>{
            if(resData.errors) 
            {
                console.log(resData.errors)
            }
            console.log(resData)  
            this.setState({returnBack: true})  
    }).catch(err=>{console.log(err)})


          
   
}


  async cancelWaste(){
    console.log(this.state.explanationValue)
    if(this.state.explanationValue === ""){
        await this.setState({errorMessage: "En az bir seçenek seçilmelidir"})
        return
    }

    if(this.state.explanationValue === "Hatalı" || this.state.explanationValue === "Yetki Dışı" || this.state.explanationValue === "Bulunamadı" || this.state.explanationValue === "Yanıltıcı" || this.state.explanationValue === "Farklı Bölge"){
        await this.setState({wasteStatus: "İptal Edildi"})
    }else if(this.state.explanationValue === "Çözüm Arayışı" || this.state.explanationValue === "Farklı Kurum" ){
        await this.setState({wasteStatus: "Çözüm Aranıyor"})
    }
    const requestBody = {
        query: `
            mutation{
                changeWasteStatus(_id:"${this.state.report._id}",status:"${this.state.wasteStatus}", explanation:"${this.state.explanationValue}", registeredMinistry:"${this.state.report.registeredMinistry._id}")
                {
                    _id
                }
            }
        `}
        
        call(requestBody).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData) 
            this.setState({returnBack: true})           
        }).catch(err=>{console.log(err)})
  }

    render(){
        console.log(this.props.location)
      if( this.state.returnBack){
        return <Redirect to='/auth'/>;
      }
    
      if(this.state.latitude !== null){
        return(
            <div class="page-wrapper">
                <div class="container-fluid">
                <Modal
                        isOpen={this.state.cancelModalBool}
                        style={customStyles}
                        contentLabel="Example Modal"
                        >

                        <h2>İptal Nedenini Seçin</h2>
                    
                        <form>
                        <select 
                        onChange={async (e) => {await this.setState({ explanationValue: e.target.value })}}
                        style={{width:"100%",height:"100%"}} name="cancelReasons" size="5" multiple
                        >
                            <option value="Hatalı">Hatalı/amaç dışı bildirim.</option>
                            <option value="Yetki Dışı">Raporlanan atık Belediyemizin yetki alanı dışında kalmaktadır.</option>
                            <option value="Bulunamadı">Raporlanan atık belirtilen konumda bulunamadı.</option>
                            <option value="Yanıltıcı">Bildiriminiz Belediye personelini yanıltıcı unsur içerdiği için iptal edilmiştir. Tekrarlı yanıltıcı tipte bildirimler üyelik iptali ile sonuçlanabilir.</option>
                            <option value="Çözüm Arayışı">Raporlanan atık Belediyemizin mevcut erişim imkanları dahilinde alınamamaktadır. Konuma özel çözüm arayışımız devam etmektedir. </option>
                            <option value="Farklı Kurum">Raporlanan konum farklı bir kurumun (DSİ, Büyükşehir Belediyesi, vs.) yetki alanındadır. İlgili kurumla çözüm arayışımız devam etmektedir.</option>
                            <option value="Farklı Bölge">Raporlanan atık bir özel mülkiyet ya da işletmenin sorumluluk alanında. Lütfen onları da Kollekt’e katılmaya davet et.</option>
                        </select>
                        </form>
                        <h4 style={{color:"red"}}>{this.state.errorMessage}</h4>
                        <button class="btn btn-primary" type="button" style={{marginRight:"0.3%", marginTop: "1%"}} onClick={async () => {this.cancelWaste()}}>Bildirimi İptal Et</button>
                        <button class="btn btn-primary" type="button" style={{marginLeft:"0.3%", marginTop: "1%"}} onClick={async () => {await this.setState({cancelModalBool: false, explanationValue:"", errorMessage:""})}}>Vazgeç</button> 
                     
                    </Modal>
                    <Modal
                        isOpen={this.state.finishModalBool}
                        style={customStyles}
                        contentLabel="Example Modal"
                        >

                        <h2>Tamamlanma Mesajını Seçin</h2>
                         
                        <form>
                        <select 
                        onChange={async (e) => {await this.setState({ explanationValue: e.target.value })}}
                        style={{width:"100%",height:"100%"}} name="cancelReasons" size="e" multiple
                        >
                            <option value="Islem">Bildiriminiz işleme alınmıştır.</option>
                            <option value="Toplandı">Bildirilen atık belediye ekiplerince toplanmıştır.</option>
                            <option value="Yonlendirme">Bildirilen atık Temizlik İşleri Müdürlüğü sorumluluk alanında olmayıp Belediye'nin diğer ilgili birimine yönlendirilmiştir.</option>
                            <option value="Konteyner-Toplandı">Bildirilen atık belediye ekiplerince toplanmış ve bölgeye konteyner yerleştirilmiştir. </option>
                            <option value="Mıntıka">Bölgede Belediyemiz tarafından mıntıka temizliği yapılmıştır. </option>
                        </select>
                        </form>
                        <h4 style={{color:"red"}}>{this.state.errorMessage}</h4>
                        <button class="btn btn-primary" type="button" style={{marginRight:"0.3%", marginTop: "1%"}} onClick={async () => {this.confirmWaste()}}>Bildirimi Toplandı İşaretle</button>
                        <button class="btn btn-primary" type="button" style={{marginLeft:"0.3%", marginTop: "1%"}} onClick={async () => {await this.setState({finishModalBool: false, explanationValue:"", errorMessage:""})}}>Vazgeç</button> 
                     
                    </Modal>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">Atık Tipleri</th>
                                    <th>Atık Resmi</th>
                                    {/*<th>Kullanıcı</th>*/}
                                    <th>Durum</th>

                                    <th>Bildirim Tarihi</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="txt-oflo"> {ShowKonteynerNameAccordingToType(this.state.report.wasteType)}</td>
                                <td>{this.state.report.images.length !== 0 && <img src={"https://api.kollektapp.org/" + this.state.report.images[0].slice(10)} style={{width:100, height:100}}></img>}</td>
                                {/*this.state.report.creator !== null && <td class="txt-oflo">Kullanıcı: {this.state.report.creator.email}</td>*/}
                                {this.state.report.waste_status === "bekliyor" &&
                                <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#ffbf3e"}}>{this.state.report.waste_status}</span> </td>
                                }
                                {this.state.report.waste_status === "Tamamlandı" &&
                                <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#7abe45"}}>{this.state.report.waste_status}</span> </td>
                                }
                                {this.state.report.waste_status === "İptal Edildi" &&
                                <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#fc4b6c"}}>{this.state.report.waste_status}</span> </td>
                                }
                                <td><span class="text-muted"><i class="fa fa-clock-o"></i> {dateConvertion(new Date(Number(this.state.date)))}</span></td>
                                <td><span class="text-muted"><i class="fa fa-clock-o"></i> <button class="btn btn-primary" onClick={() => this.setState({finishModalBool: true})}>Atık Toplandı</button></span></td>
                                <td><span class="text-muted"><i class="fa fa-clock-o"></i> <button class="btn btn-primary" onClick={() => this.setState({cancelModalBool: true})}>İptal Et</button></span></td>
                            </tr>
                            </tbody>

                        </table>
                                    
                    </div>  
                </div> 
                <div class="row">
                <div class="col-md-12">
                <div style={{ height: '80vh', width: '90%' }}>
                   <Map
                        onClick={this.onMapClicked}
                        google={this.props.google} 
                        zoom={17}
                        initialCenter={{
                            lat:  this.state.latitude,
                            lng: this.state.longitude
                        }}
                    >
                        <Marker onClick={this.onMarkerClick}
                        position={{lat: this.state.latitude, lng: this.state.longitude}}
                        name={"kont"}/>
                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}>
                            <div>
                            <h5>{ShowKonteynerNameAccordingToType(this.state.report.wasteType)}</h5>
                            </div>
                        </InfoWindow>
                    </Map>             
                </div>
                </div>
                </div>
               
            </div>
        )     
    }else{
        return (
            <h1>wait</h1>
        )
    }
}
}
const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '60%'
    }
  };
  
export default GoogleApiWrapper({
    apiKey: ("AIzaSyAdA0AjaFE0dcPMMqv9dRT06Pb1_A9xSS4")
  })(WasteReportDetailPage)
