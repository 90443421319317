import {SET_CURRENT_USER} from '../actiontypes'

const DEFAULT_STATE = {
    isAuthenticated : false,
    user:{}
}

export default (state= DEFAULT_STATE , action)=>{
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated : !!Object.keys(action.user).length,
                user:action.user
            }
        default:
            return state;
    }
}