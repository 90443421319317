import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'

import {call} from '../../services/api';

class PastEvents extends Component{

    state = {
        events: [],
        konteyners: [],
        dataCame: false
    }

    componentWillMount(){
        const requestBody = {
            query: `
                query{
                    getPastEvents
                    {
                        _id
                        name
                        description
                        time
                        createdAt
                        place
                        isActive
                        participants{_id}
                    }
                }
            `
        }
        



        
        call(requestBody).then(async resData=>{
                if(resData.errors) 
                {
                    console.log("unauthenticated")
                }
                console.log(resData)
             
               
                await this.setState({events: resData.data.getPastEvents, dataCame:true})
                
        }).catch(err=>{console.log(err)})
    }


    componentDidUpdate() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `$('#example23').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ]
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        if(this.state.dataCame)
        {
            document.body.appendChild(s);
        }
    }

    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">



            <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Tüm Etkinlikler</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                <li class="breadcrumb-item active">Tüm Etkinlikler</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">Geçmiş Etkinlikler</h4>
                        <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                        <div className="table-responsive m-t-40">
                            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                            <thead>
                                <tr>
                                <th>Etkinlik Adı</th>
                                <th>Etkinlik Tanımı</th>
                                <th>Etkinlik Tarihi</th>
                                <th>Etkinlik Yeri</th>
                                <th>Katılımcı Sayısı</th>
                                <th>Etkinlik Durumu</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Etkinlik Adı</th>
                                <th>Etkinlik Tanımı</th>
                                <th>Etkinlik Tarihi</th>
                                <th>Etkinlik Yeri</th>
                                <th>Katılımcı Sayısı</th>
                                <th>Etkinlik Durumu</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {this.state.events.map(event => {
                                    return(
                                        <tr>
                                        <td>{event.name}</td>
                                        <td>{event.description}</td>
                                        <td>{dateConvertion(new Date(Number(event.time)))}</td>
                                        <td>{event.place}</td>
                                        <td>{event.participants.length}</td>
                                        <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#fc4b6c"}}>Geçmiş</span> </td>
                                        </tr> 
                                      )              
                                })}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
               
         
        )
    }
}

export default PastEvents