exports.ShowKonteynerNameAccordingToType = (type) => {
    if(type.length !== 0){
      var names = []
      type.map(typeNumber => {
        if(typeNumber === -1){
          names.push("Ben")
        }else if(typeNumber === 1){
          names.push("pet şişe/plastik")
        }else if(typeNumber === 2){
          names.push("cam")
        }else if(typeNumber === 3){
          names.push("kağıt/karton")
        }else if(typeNumber === 4){
          names.push("teneke kutu/metal")
        }else if(typeNumber === 5){
          names.push("mutfak/yemek artıkları")
        }else if(typeNumber === 6){
          names.push("inşaat/yıkıntı")
        }else if(typeNumber === 7){
          names.push("bebek bezi")
        }else if(typeNumber === 8){
          names.push("park bahçe atıkları")
        }else if(typeNumber === 9){
          names.push("araba lastiği")
        }else if(typeNumber === 10){
          names.push("ev eşyası")
        }else if(typeNumber === 11){
          names.push("cam kırıkları")
        }else if(typeNumber === 12){
          names.push("tıbbi atık")
        }else if(typeNumber === 13){
          names.push("elektronik")
        }else if(typeNumber === 14){
          names.push("bitkisel yağ")
        }else if(typeNumber === 15){
          names.push("tekstil")
        }else if(typeNumber === 16){
          names.push("meyve/sebze artıkları")
        }else if(typeNumber === 17){
          names.push("pil")
        }else if(typeNumber === 18){
          names.push("ilaç")
        }else if(typeNumber === 19){
          names.push("akü/elektrikli araç bataryası")
        }else if(typeNumber === 20){
          names.push("atık motor yağı")
        }else if(typeNumber === 21){
          names.push("Eldiven/Maske")
        }
      })
      var returnedNames = ""
      names.map(name => {
        if(returnedNames === ""){
          returnedNames = name
        }else {
          returnedNames = returnedNames + ", " + name
        }
        
      })
        return returnedNames
      }
      return "Bulunduğum Konum"
  }

exports.showKonteynerTypeName = (tNumber) => {
    if(tNumber === 1){
        return "Belediye Tipi"
    }else if(tNumber === 2){
        return "Cam Kumbarası"
    }else if(tNumber === 3){
        return "Pet Şişe Kafesi"
    }else if(tNumber === 4){
        return "Karışık Ambalaj"
    }else if(tNumber === 5){
        return "Tekli Çoklu Özel Kutu"
    }else if(tNumber === 6){
        return "Atık Getirme Merkezi"
    }
}
 exports.showKonteynerStatusName= (number) => {
    if(number === 1){
        return "Sağlam"
    }else if(number === 2){
        return "Bakım Gerekli"
    }else if(number === 3){
        return "Değişim Gerekli"
    }
}