import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'

import {call} from '../../services/api';


class WasteReporting extends Component{

    state = {
        wasteReports: [],
        konteyners: [],
        dataCame: false,
        typeCounts: [{count: 0, isim:"Pet Şişe"}],
        aylik: false,
        yillik: true,
        yillar: [],
        aylar: [],
        aylarText: ["Ay", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        yearValue: 2018,
        monthValue: 0,
        startShowing: false,
        filteredWasteReports: [],
        totalCount:0
    }

    async componentWillMount(){
        var yil = []
        var ay = []
        for(var i = 0; i < 25; i++){
            yil.push(2018 + i)
        }
        for(var i = 0; i <= 12; i++){
            ay.push(i)
        }
        
        await this.setState({yillar: yil, aylar: ay})

        await this.setState({
            typeCounts: [ 
                {count: 0, isim:"Pet şişe"}, //1
                {count: 0, isim:"Cam"}, //2
                {count: 0, isim:"Kağıt"}, //3
                {count: 0, isim:"Aliminyum Teneke"}, //4
                {count: 0, isim:"Mutfak Atıkları"}, //5
                {count: 0, isim:"Moloz"}, //6
                {count: 0, isim:"Bebek Bezi"}, //7
                {count: 0, isim:"Budama"}, //8
                {count: 0, isim:"Araba Lastiği"}, //9
                {count: 0, isim:"Eski Ev Eşyaları"}, //10
                {count: 0, isim:"Cam Kırıkları"}, //11
                {count: 0, isim:"Tıbbi atık"}, //12
                {count: 0, isim:"elektronik"}, //13
                {count: 0, isim:"Bitkisel yağ"}, //14
                {count: 0, isim:"Tekstil"}, //15
                {count: 0, isim:"Organik"}, //16
                {count: 0, isim:"Pil"}, //17
                {count: 0, isim:"İlaç"},//18
                {count: 0, isim:"Akü/Elektrikli araç bataryası"}, //19 
                {count: 0, isim:"Atık motor yağı"}, // 20
            ]
        })
    }


    loadData(year,month){
        const requestBody = {
            query: `
            query{
                getWasteStats(year:${year},month:${month}){
                  _id
                  count
                }
              }
            `}
        
        console.log(month)

        call(requestBody).then(async resData=>{
                if(resData.errors) 
                {
                    console.log("unauthenticated")
                }
                else{
                    console.log(resData)

                    await this.setState({
                        typeCounts: [ 
                            {count: 0, isim:"Pet şişe"}, //1
                            {count: 0, isim:"Cam"}, //2
                            {count: 0, isim:"Kağıt"}, //3
                            {count: 0, isim:"Aliminyum Teneke"}, //4
                            {count: 0, isim:"Mutfak Atıkları"}, //5
                            {count: 0, isim:"Moloz"}, //6
                            {count: 0, isim:"Bebek Bezi"}, //7
                            {count: 0, isim:"Budama"}, //8
                            {count: 0, isim:"Araba Lastiği"}, //9
                            {count: 0, isim:"Eski Ev Eşyaları"}, //10
                            {count: 0, isim:"Cam Kırıkları"}, //11
                            {count: 0, isim:"Tıbbi atık"}, //12
                            {count: 0, isim:"elektronik"}, //13
                            {count: 0, isim:"Bitkisel yağ"}, //14
                            {count: 0, isim:"Tekstil"}, //15
                            {count: 0, isim:"Organik"}, //16
                            {count: 0, isim:"Pil"}, //17
                            {count: 0, isim:"İlaç"},//18
                            {count: 0, isim:"Akü/Elektrikli araç bataryası"}, //19 
                            {count: 0, isim:"Atık motor yağı"}, // 20
                        ]
                    })

                    this.setState({totalCount : 0})    


                    resData.data.getWasteStats.forEach(wasteType => {
                         var typeCounts = this.state.typeCounts
                         console.log(parseInt(wasteType._id)-1)
                         if(!(parseInt(wasteType._id)-1 < 0))
                         {
                            typeCounts[parseInt(wasteType._id)-1].count = wasteType.count
                            this.setState({typeCounts:typeCounts})
                            console.log(typeCounts)
   
                            this.setState({totalCount : this.state.totalCount + wasteType.count}) 
                         }
   
                    });
                }
            }).catch(err=>{console.log(err)})
    }



    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }

    render(){
       
        return (

        <div class="page-wrapper">
            <div class="container-fluid">
                        
                        <div className="card">
                        <div className="card-body">
                        
                        <div class="col-md-12 row">
                        <div class="col-md-3">
                            <div class="card bg-primary text-white">
                                    <div class="card-body">
                                        <h5 class="card-title">Tarih Filitrelemesi</h5>
                                        <div class="row">
                                            <div class="col-6  m-t-30 row">
                                            <div className="col-md-5">
                                            <select class="form-control-lg " onChange={async (e) => {await this.setState({ yearValue: e.target.value })}}>
                                                {this.state.yillar.map(yil => {
                                                    return(
                                                        <option value={yil}>{yil}</option>
                                                    )
                                                })}
                                            </select>

                                            </div>
                                            <div style={{float:'right',marginLeft:20}} className="col-md-5">

                                            <select class="form-control-lg " onChange={async (e) => {await this.setState({ monthValue: e.target.value }); console.log(this.state.monthValue)}}>

                                                {this.state.aylar.map(ay => {
                                                    return(
                                                        <option value={ay}>{this.state.aylarText[ay]}</option>
                                                    )
                                                })}


                                            </select>

                                            </div>
                                            
                                            <div className="col-md-12 text-center">
                                                <button class="btn btn-dark btn-lg" type="button" style={{marginTop:"4%",marginBottom:"4%"}} onClick={async () => {this.loadData(this.state.yearValue,this.state.monthValue)}}>Raporu Görüntüle</button>

                                            </div>

                                            </div>
                                            <div class="col-6">
                                                <div id="sales1" class="text-right"></div>
                                            </div>
                                        </div>

                                        <h5 class="card-title">Toplam Atık Bildirimi</h5>
                                        <div class="row">
                                            <div class="col-6  m-t-30">
                                                <h1 class="text-white">{this.state.totalCount}</h1>
                                                 </div>
                                            <div class="col-6">
                                                <div id="sales1" class="text-right"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-9">
                            <div>
                            <div className="table-responsive">
                            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                            <thead>
                                <tr>
                                <th>Atık Tipi</th>
                                <th>Bildirim Sayısı</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Atık Tipi</th>
                                <th>Bildirim Sayısı</th>                               
                                </tr>
                            </tfoot>
                            <tbody>
                                {this.state.typeCounts.map(type => {
                                    return(
                                        <tr>
                                        <td>{type.isim}</td>
                                        <td>{type.count}</td>
                                        </tr> 
                                    )              
                                })}   
                            </tbody>
                            </table>
                        </div>
                        </div>
                        </div>



                        </div>

 
                        

                    </div>
                    </div>

                    {this.state.startShowing && this.state.dataCame && this.state.filteredWasteReports.length == 0 && 
                        <div className="card">
                        <div className="card-body">
                            <center><p>Filitrelenen tarihe ait bildirim bulunamadı.</p></center>
                            </div>
                            </div>
                    }

                </div>
            </div>
           
                       
         
        )
    }
}

export default WasteReporting

