exports.createDataTable = () => {
    let s = document.createElement("script")
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `$('#example23').DataTable({
                    retrieve: true,
                
                        dom: 'Bfrtip',
                    buttons: [
                        'copy', 'csv', 'excel', 'pdf', 'print'
                    ],
                     "language": {
                        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                    }
                });
                $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
            `;
    document.body.appendChild(s)
}