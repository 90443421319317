import React from 'react'

import AuthContext from '../context/auth-context'
import {connect} from 'react-redux';

const Header = props =>(
        <AuthContext.Consumer>
            {(context)=>{
                if(props.auth.isAuthenticated)
                {
                   return ( <header className="topbar">
                   <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                     
                     <div className="navbar-collapse">
                       <ul className="navbar-nav mr-auto">
                         <li className="nav-item"> <a className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i className="ti-menu" /></a> </li>
                         <li className="nav-item"> <a className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i className="icon-menu" /></a> </li>
                       </ul>
                       <ul className="navbar-nav my-lg-0">
                        
                         <li className="nav-item dropdown mega-dropdown"> <a className="nav-link dropdown-toggle waves-effect waves-dark" href data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-layout-width-default" /></a>
                           <div className="dropdown-menu animated bounceInDown">
                             <ul className="mega-dropdown-menu row">
                               <li className="col-lg-3 col-xlg-2 m-b-30">
                                 <h4 className="m-b-20">Duyurular</h4>
                                 <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                   <div className="carousel-inner" role="listbox">
                                     <div className="carousel-item active">
                                       <div className="container"> <img className="d-block img-fluid" src="assets/images/big/img1.jpg" alt="First slide" /></div>
                                     </div>
                                     <div className="carousel-item">
                                       <div className="container"><img className="d-block img-fluid" src="assets/images/big/img2.jpg" alt="Second slide" /></div>
                                     </div>
                                     <div className="carousel-item">
                                       <div className="container"><img className="d-block img-fluid" src="assets/images/big/img3.jpg" alt="Third slide" /></div>
                                     </div>
                                   </div>
                                   <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true" /> <span className="sr-only">Previous</span> </a>
                                   <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true" /> <span className="sr-only">Next</span> </a>
                                 </div>
                               </li>
                               <li className="col-lg-3 m-b-30">
                                 <h4 className="m-b-20">Sıkça Sorulan Sorular</h4>
                                 <div className="accordion" id="accordionExample">
                                   <div className="card m-b-0">
                                     <div className="card-header bg-white p-0" id="headingOne">
                                       <h5 className="mb-0">
                                         <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                           #1 Atık bildirimi nedir 
                                         </button>
                                       </h5>
                                     </div>
                                     <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                       <div className="card-body">
                                         Anim pariatur cliche reprehenderit, enim eiusmod high.
                                       </div>
                                     </div>
                                   </div>
                                   <div className="card m-b-0">
                                     <div className="card-header bg-white p-0" id="headingTwo">
                                       <h5 className="mb-0">
                                         <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                           #2 Konteynır sistemi nasıl çalışır
                                         </button>
                                       </h5>
                                     </div>
                                     <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                       <div className="card-body">
                                         Anim pariatur cliche reprehenderit, enim eiusmod high.
                                       </div>
                                     </div>
                                   </div>
                                   <div className="card m-b-0">
                                     <div className="card-header bg-white p-0" id="headingThree">
                                       <h5 className="mb-0">
                                         <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                           Raporlar neyi gösterir #3
                                         </button>
                                       </h5>
                                     </div>
                                     <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                       <div className="card-body">
                                         Anim pariatur cliche reprehenderit, enim eiusmod high.
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </li>
                               <li className="col-lg-3  m-b-30">
                                 <h4 className="m-b-20">İletişime Geç</h4>
                                 <form>
                                   <div className="form-group">
                                     <input type="text" className="form-control" id="exampleInputname1" placeholder="Yetkili Adı" /> </div>
                                   <div className="form-group">
                                     <input type="email" className="form-control" placeholder="Telefon Numarası" /> </div>
                                   <div className="form-group">
                                     <textarea className="form-control" id="exampleTextarea" rows={3} placeholder="Mesaj" defaultValue={""} />
                                   </div>
                                   <button type="submit" className="btn btn-info">Gönder</button>
                                 </form>
                               </li>
                             </ul>
                           </div>
                         </li>
                         <li className="nav-item right-side-toggle"> <a className="nav-link  waves-effect waves-light" href="javascript:void(0)"><i className="ti-settings" /></a></li>
                       </ul>
                     </div>
                   </nav>
                 </header>)
                }
                else{
                    return null
                }
            }}
        </AuthContext.Consumer>
)

export default connect((store)=>({auth:store.auth}))(Header)