import React, { Component } from 'react'
import Select from 'react-select'


class DateOptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGun: { value: 0, label: "Tümü" },
            selectedYil: { value: 0, label: "Tümü" },
            selectedAy: { value: 0, label: "Tümü" },
            yillar: [],
            aylar: [],
            gunler: [],
            aylarText: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],

        }
    }

    async componentWillMount(){
        this.setTimes()
    }

    async setTimes() {
        var yil = []
        let yillarOption = [{ value: 0, label: "Tümü" }]
        var ay = []
        let aylarOption = [{ value: 0, label: "Tümü" }]
        for (var i = 0; i < 25; i++) {
            yil.push(2019 + i)
            yillarOption.push({ value: 2019 + i, label: 2019 + i })
        }
        for (var i = 1; i < 13; i++) {
            ay.push(i)
            aylarOption.push({ value: i, label: this.state.aylarText[i - 1] })

        }
        var gun = []
        let gunlerOption = [{ value: 0, label: "Tümü" }]
        for (let i = 1; i <= 31; i++) {
            gun.push(i)
            gunlerOption.push({ value: i, label: i })
        }
        await this.setState({ gunlerOptions: gunlerOption, aylarOptions: aylarOption, yillarOptions: yillarOption, yillar: yil, aylar: ay, gunler: gun })
    }

    render() {
        return (
            <React.Fragment>
                <label>Gün</label>
                <Select value={this.props.selectedGun} onChange={(selectedOption) => { this.props.setFunction(this.props.gun, selectedOption); this.setState({selectedGun: selectedOption}) }} isMulti={false} options={this.state.gunlerOptions} placeholder="Tümü" />
                <label>Ay</label>
                <Select value={this.props.selectedAy} onChange={(selectedOption) => { this.props.setFunction(this.props.ay, selectedOption); this.setState({selectedAy: selectedOption}) }} isMulti={false} options={this.state.aylarOptions} placeholder="Tümü" />
                <label>Yıl</label>
                <Select value={this.props.selectedYil} onChange={(selectedOption) => { this.props.setFunction(this.props.yil, selectedOption); this.setState({selectedYil: selectedOption}) }} isMulti={false} options={this.state.yillarOptions} placeholder="Tümü" />
            </React.Fragment>
        )
    }
}

export default DateOptions