import axios from 'axios'


export const setToken = async (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Barear ${token}`
    }
    else {
        delete axios.defaults.headers.common['Authorization']
    }
}

export const call = async (data) => {
    axios.defaults.baseURL = 'https://api.kollektapp.org/'
    //axios.defaults.baseURL = 'http://localhost:4000/graphql/'
    const response = await axios.post('/', data)
    console.log(response)
    return response.data
}

export default { call, setToken };