import {addError,removeError} from './error';
import {SET_CURRENT_USER} from '../actiontypes'
import api from '../../services/api'

export const setCurrentUser = (user)=>({
    type:SET_CURRENT_USER,
    user
})

export const setToken = (token)=>{
    api.setToken(token)
}

export const logout = ()=>{
    return dispatch => {
        localStorage.clear();
        api.setToken(null);
        dispatch(setCurrentUser({}))
        dispatch(removeError())
    }
}

export const authUser = (token,user)=>{
    return async dispatch => {
        try {
            localStorage.setItem("jwtToken", token)
            api.setToken(token)
            dispatch(setCurrentUser(user))
            dispatch(removeError())
        } catch (error) {
            const {err} = error.response.data
            dispatch(addError(err))
        }
    }
}