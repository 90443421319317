import React, { Component } from 'react'
import AuthContext from '../context/auth-context'
import GoogleMapReact from 'google-map-react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { showKonteynerStatusName, showKonteynerTypeName, ShowKonteynerNameAccordingToType } from './helpers/markerHelpers'
import Select from 'react-select';
import { limitOptions } from './wasteReports/activeWasteReports'
import { createDataTable } from './helpers/dataTable';
import { call } from '../services/api'


class Konteyners extends Component {

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        mahalle: "",
        sokak: "",
        konteynerTipi: "",
        konteyners:[],
        konteynerAdet: "",
        konteynerDurum: "",
        konteyner_image: "",
        konteynerAtilacaklar: "",
        dataCame: false,
        mapDataCame: false,
        mapLatLong: [],
        limit: 50,
        skip: 0
    };

    konteynerStringConverter(types) {
        var typeString = ""
        types.forEach(type => {

            switch (type) {
                case 1:

                    typeString = typeString + "Pet Şişe - "
                    break;
                case 2:
                    console.log("Cam")
                    typeString = typeString + "Cam - "
                    break;
                case 3:
                    typeString = typeString + "Kağit/Karton - "
                    break;
                case 4:
                    typeString = typeString + "Teneke Kutu/Metal - "
                    break;
                case 5:
                    typeString = typeString + "Mutfak/Yemek Artığı - "
                    break;
                case 6:
                    typeString = typeString + "İnşaat/Yıkıntı - "
                    break;
                case 7:
                    typeString = typeString + "Bebek Bezi - "
                    break;
                case 8:
                    typeString = typeString + "Park Bahçe Atıkları - "
                    break;
                case 9:
                    typeString = typeString + "Araba Lastiği - "
                    break;
                case 10:
                    typeString = typeString + "Eski Ev Eşyaları - "
                    break;
                case 11:
                    typeString = typeString + "Cam Kırıkları ve Süprüntüler - "
                    break;
                case 12:
                    typeString = typeString + "Tıbbi Atık - "
                    break;
                case 13:
                    typeString = typeString + "Elektronik Atık - "
                    break;
                case 14:
                    typeString = typeString + "Bitkisel Atık Yağ - "
                    break;
                case 15:
                    typeString = typeString + "Tekstil Atığı - "
                    break;
                case 16:
                    typeString = typeString + "Meyve/Sebze Atıkları - "
                    break;
                case 17:
                    typeString = typeString + "Pil - "
                    break;
                case 18:
                    typeString = typeString + "Atık İlaç - "
                    break;
                case 19:
                    typeString = typeString + "Akü/Elektrikli Araç Bataryası - "
                    break;
                case 20:
                    typeString = typeString + "Atık Motor Yağı - "
                    break;
                case 21:
                    typeString = typeString + "Maske/Eldiven - "
                    break;
                default:
                    break;
            }

        });
        return typeString
    }

    convertKonteynerTypeToString(konteynerType) {
        switch (konteynerType) {
            case 1:
                return "Belediye"
                break;
            case 2:
                return "Cam Kumbarası"
                break;
            case 3:
                return "Pet Şişe Kafesi"
                break
            case 4:
                return "Karışık Ambalaj"
                break;
            case 5:
                return "Tekli Çoklu Özel"
                break
            case 6:
                return "Atık Getirme Merkezi"
                break
            case 7:
                return "Maske/Eldiven"
                break
            default:
                break;
        }


    }


    componentDidMount() {
        this.loadMore()
    }

    loadMore = async () => {
        const { skip, limit, konteyners } = this.state;
        await this.setState({ dataCame: false })
        const requestBody = {
            query: `
                query{
                    konteyners(skip: ${skip}, limit: ${limit})
                    {
                        _id
                        konteynerType
                        konteynerNumber
                        type
                        emptyFrequency
                        status
                        mahalle
                        sokak
                        latitude
                        longitude
                        konteyner_image
                    }
                }
            `
        }

        const ministryRequest = {
            query: `
                query{
                    getMinistryById
                    {
                        latitude
                        longitude
                    }
                }
            `
        }

        call(requestBody).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            } else {
                let newKonteyners = [...konteyners, ...resData.data.konteyners];
                let newSkip = skip + resData.data.konteyners.length;
                this.setState({
                    konteyners: newKonteyners,
                    dataCame: true,
                    skip: newSkip,
                    disableLoadMore: resData.data.konteyners.length === 0
                })
                createDataTable();
            }
        }).catch(err => { console.log(err); this.setState({ dataCame: true }); createDataTable() })

        call(ministryRequest).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            console.log(resData)
            this.setState({ mapLatLong: resData.data.getMinistryById, mapDataCame: true })
            
        }).catch(err => { console.log(err) })
    }

    onMarkerClick = async (props, marker, e) => {

        await this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            mahalle: marker.name.mahalle,
            sokak: marker.name.sokak,
            konteyner_image: marker.name.konteyner_image,
            konteynerTipi: showKonteynerTypeName(marker.name.konteynerType),
            konteynerAdet: marker.name.konteynerNumber,
            konteynerDurum: showKonteynerStatusName(marker.name.status),
            konteynerAtilacaklar: ShowKonteynerNameAccordingToType(marker.name.type)
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };



    render() {
        if (!this.state.dataCame) {
            return (
                <h1>Bekleyin</h1>
            )

        } else {
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">

                        <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">Konteynerler</h4>
                            </div>
                            <div class="col-md-7 align-self-center text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                        <li class="breadcrumb-item active">Konteynerler</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select 
                                styles={{ input: styles => ({ ...styles, width: 50 }) }}
                                value={{value: this.state.limit, label: this.state.limit}} 
                                onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                                isMulti={false} options={limitOptions} placeholder="50"
                            />
                            <button class="btn btn-primary" disabled={this.state.disableLoadMore} onClick={this.loadMore}>{this.state.limit} adet daha yükle</button>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Konteynerler</h4>
                                        <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                        <div className="table-responsive m-t-40">
                                            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Konteyner Tipi</th>
                                                        <th>Konteyner Sayısı</th>
                                                        <th>Konteyner Tipleri</th>
                                                        <th>Resim</th>
                                                        <th>Mahalle</th>
                                                        <th>Sokak</th>
                                                        <th>Enlem</th>
                                                        <th>Boylam</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Konteyner Tipi</th>
                                                        <th>Konteyner Sayısı</th>
                                                        <th>Konteyner Tipleri</th>
                                                        <th>Resim</th>
                                                        <th>Mahalle</th>
                                                        <th>Sokak</th>
                                                        <th>Enlem</th>
                                                        <th>Boylam</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {this.state.konteyners.map(rep => {
                                                        return (
                                                            <tr>
                                                                <td>{this.convertKonteynerTypeToString(rep.konteynerType)}</td>
                                                                <td>{rep.konteynerNumber}</td>
                                                                <td>{this.konteynerStringConverter(rep.type)}</td>
                                                                {rep.konteyner_image !== null
                                                                    ?
                                                                    <td><img style={{ width: 100, height: 100 }} src={"https://api.kollektapp.org/" + rep.konteyner_image.slice(10)} /></td>
                                                                    :
                                                                    <td>Resim yok</td>
                                                                }
                                                                <td>{rep.mahalle}</td>
                                                                <td>{rep.sokak}</td>
                                                                <td>{rep.latitude}</td>
                                                                <td>{rep.longitude}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div style={{ height: '90vh', width: '90%', padding: 20, overflow: 'hidden' }} className="card-body">
                                        <h4 className="card-title">Aktif Atık Bildirimleri</h4>
                                        {this.state.mapDataCame &&
                                            <Map styles={{ padding: '5%' }}
                                                onClick={this.onMapClicked}
                                                google={this.props.google}
                                                zoom={14}
                                                initialCenter={{
                                                    lat: this.state.mapLatLong.latitude,
                                                    lng: this.state.mapLatLong.longitude
                                                }}
                                            >

                                                {this.state.konteyners.map(kont =>

                                                    <Marker onClick={this.onMarkerClick}
                                                        position={{ lat: kont.latitude, lng: kont.longitude }}
                                                        name={kont} />

                                                )}
                                                <InfoWindow
                                                    marker={this.state.activeMarker}
                                                    visible={this.state.showingInfoWindow}>
                                                    <div>
                                                        <h5><b>Mah:</b> {this.state.mahalle}</h5>
                                                        <p><b>Sokak:</b> {this.state.sokak}</p>
                                                        <p><b>Tip:</b> {this.state.konteynerTipi}</p>
                                                        <p><b>Adet:</b> {this.state.konteynerAdet}</p>
                                                        <p><b>Durum:</b> {this.state.konteynerDurum}</p>
                                                        <p><b>Neler Atılabilir:</b> {this.state.konteynerAtilacaklar}</p>
                                                        {this.state.konteyner_image !== null
                                                            ?
                                                            <img style={{ width: 300, height: 300 }} src={"https://api.kollektapp.org/" + this.state.konteyner_image.slice(10)} />
                                                            :
                                                            <p>Resim yok</p>
                                                        }
                                                    </div>
                                                </InfoWindow>
                                            </Map>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )



        }
    }
}
export default GoogleApiWrapper({
    apiKey: ("AIzaSyAdA0AjaFE0dcPMMqv9dRT06Pb1_A9xSS4")
})(Konteyners)