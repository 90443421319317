import React from 'react'
import {NavLink} from 'react-router-dom'

import {connect} from 'react-redux';

import AuthContext from '../context/auth-context'

const Sidebar = props =>(
    <AuthContext.Consumer>
       {(context)=>{
            if(props.auth.isAuthenticated)
            {
                return (<aside class="left-sidebar">
                <div class="scroll-sidebar">
                    <nav class="sidebar-nav">
                        <ul id="sidebarnav">
                            <li> <a class="waves-effect waves-dark" href="/index" ><i class="icon-speedometer"></i><span class="hide-menu">Anasayfa </span></a>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-login"></i><span class="hide-menu">Atık Bildirimleri</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="activeWasteReports">Aktif Bildirimler</a></li>
                                    <li><a href="pastWasteReports">Geçmiş Bildirimler</a></li>
                                    <li><a href="canceledWasteReports">İptal Edilen Bildirimler</a></li>
                                    <li><a href="allWasteReports">Tüm Bildirimler</a></li>
                                    <li><a href="advancedReportQuery">Gelişmiş Bildirim Filtreleme</a></li>
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-bar-chart-alt"></i><span class="hide-menu">Excel Veri İndirme</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="downloadExcel">Excel İndir</a></li>
                                    {/* <li><a href="advancedUserQuery">Tarihe Göre Kullanıcı</a></li> */}
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-bar-chart-alt"></i><span class="hide-menu">Raporlar</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="wasteReporting">Atık Raporları</a></li>
                                    <li><a href="userReporting">Kullanıcı Raporları</a></li>
                                    <li><a href="wasteReportPlace">Bildirim Lokasyonları</a></li>
                                </ul>
                            </li>
        
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-direction"></i><span class="hide-menu">Konteynerlar</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/konteyner">Tüm Konteynerlar</a></li>
                                </ul>
                            </li>
        
                            {/*<li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-pin"></i><span class="hide-menu">Etkinlikler</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/createEvent">Etkinlik Talebi</a></li>
                                    <li><a href="/activeEvents">Aktif Etkinlikler</a></li>
                                    <li><a href="/pastEvents">Geçmiş Etkinlikler</a></li>
                                    <li><a href="/allEvents">Tüm Etkinlikler</a></li>
                                </ul>
                            </li>*/}
        
                            <li class="nav-small-cap">--- KULLANICI HESAP İŞLEMLERİ</li>
        
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-email"></i><span class="hide-menu">Hesap İşlemleri</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="allAccounts">Tüm Hesaplar</a></li>
                                    <li><a href="createAccount">Yeni Hesap Oluştur</a></li>
                                </ul>
                            </li>
                            <li> <a href="/logout" class="waves-effect waves-dark" aria-expanded="false"><i class="icon-speedometer"></i><span class="hide-menu">Çıkış Yap </span></a>
                            </li>
                        </ul>
                    </nav>
                 </div>
                </aside>)
            }
            else{
                return null
            }
       }}
    </AuthContext.Consumer>
)

export default connect((store)=>({auth:store.auth}))(Sidebar)