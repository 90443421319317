import React, { Component } from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import { dateConvertion } from '../helpers/dateHelpers'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { call } from '../../services/api';
import reportIndicator from '../../images/reportIndicator.png'
import { showKonteynerStatusName, showKonteynerTypeName, ShowKonteynerNameAccordingToType } from '../helpers/markerHelpers'
import Select from 'react-select';
import { limitOptions } from '../wasteReports/activeWasteReports'

class WasteReportPlace extends Component {

    state = {
        wasteReports: [],
        konteyners: [],
        dataCame: false,
        limit: 50,
        skip: 0,
        typeCounts: [{ count: 0, isim: "Pet Şişe" }],
        aylik: false,
        yillik: true,
        yillar: [],
        aylar: [],
        aylarText: ["", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        yearValue: 2018,
        monthValue: 1,
        startShowing: false,
        filteredWasteReports: [],

        //for map
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        latitude: null,
        longitude: null,
        report: { createdAt: "asd" },
        date: null,
        returnBack: false,
        explanationValue: "",
        cancelModalBool: false,
        finishModalBool: false,
        wasteStatus: "",
        errorMessage: "",

        repId: "",
        repImage: "",
        repInside: "",
        repUser: "",
        repDate: "",
        wasteStatus: "",

        mapDataCame: false,
        mapLatLong: []
    }

    async componentWillMount() {
        var yil = []
        var ay = []
        for (var i = 0; i < 25; i++) {
            yil.push(2018 + i)
        }
        for (var i = 1; i < 13; i++) {
            ay.push(i)
        }

        await this.setState({ yillar: yil, aylar: ay })
        await this.setState({
            typeCounts: [
                { count: 0, isim: "Pet şişe" }, //1
                { count: 0, isim: "Cam" }, //2
                { count: 0, isim: "Kağıt" }, //3
                { count: 0, isim: "Aliminyum Teneke" }, //4
                { count: 0, isim: "Mutfak Atıkları" }, //5
                { count: 0, isim: "Moloz" }, //6
                { count: 0, isim: "Bebek Bezi" }, //7
                { count: 0, isim: "Budama" }, //8
                { count: 0, isim: "Araba Lastiği" }, //9
                { count: 0, isim: "Eski Ev Eşyaları" }, //10
                { count: 0, isim: "Cam Kırıkları" }, //11
                { count: 0, isim: "Tıbbi atık" }, //12
                { count: 0, isim: "elektronik" }, //13
                { count: 0, isim: "Bitkisel yağ" }, //14
                { count: 0, isim: "Tekstil" }, //15
                { count: 0, isim: "Organik" }, //16
                { count: 0, isim: "Pil" }, //17
                { count: 0, isim: "İlaç" },//18
                { count: 0, isim: "Akü/Elektrikli araç bataryası" }, //19 
                { count: 0, isim: "Atık motor yağı" }, //19 
            ]
        })

        const ministryRequest = {
            query: `
                query{
                    getMinistryById
                    {
                        latitude
                        longitude
                    }
                }
            `
        }
        await this.setState({ mapDataCame: false })
        call(ministryRequest).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            console.log(resData)
            this.setState({ mapLatLong: resData.data.getMinistryById, mapDataCame: true })

        }).catch(err => {
            console.log(ministryRequest); this.setState({ mapLatLong: { latitude: 36.59778, longitude: 30.56056 }, mapDataCame: true })
        })

        this.loadMore();
    }

    loadMore = async () => {
        const { skip, limit, wasteReports } = this.state;
        await this.setState({ dataCame: false })

        const requestBody = {
            query: `
                query{
                    wasteReportsAllNotLimited(skip: ${skip}, limit: ${limit})
                    {
                        _id
                        images
                        privacy
                        wasteType
                        latitude
                        longitude
                        createdAt
                        isSelf
                        waste_status
                        statusExplanation
                        createdAt
                        creator {email}
                    }
                }
            `
        }

        call(requestBody).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            } else {
                let wasteRepr = []
                resData.data.wasteReportsAllNotLimited.map(rep => {
                    if (rep.isSelf === false) {
                        wasteRepr.push(rep)
                    }
                })
                const newSkip = skip + resData.data.wasteReportsAllNotLimited.length;
                const newWasteReports = [...wasteReports, ...resData.data.wasteReportsAllNotLimited];
                await this.setState({
                    wasteReports: newWasteReports,
                    dataCame: true,
                    skip: newSkip,
                    disableLoadMore: resData.data.wasteReportsAllNotLimited.length === 0,
                })
            }
        }).catch(err => { console.log(err); this.setState({ dataCame: true }) })
    }

    showStatus(stat) {
        if (stat === "bekliyor") {
            return "beklemede"
        }
    }

    async setFilteredWasteReports() {
        var forFilteredReport = []
        await this.state.wasteReports.map(rep => {
            if (this.state.yillik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue)) {
                forFilteredReport.push(rep)
            } else if (this.state.aylik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue) && new Date(Number(rep.createdAt)).getMonth() + 1 === Number(this.state.monthValue)) {
                forFilteredReport.push(rep)
            }
        })


        await this.setState({ filteredWasteReports: forFilteredReport })

        await this.setState({ startShowing: true })

    }

    async typeCountAdder(typeNumber) {
        var typeArray = this.state.typeCounts

        typeArray[typeNumber - 1].count++
        await this.setState({ typeCounts: typeArray })
    }

    countAccordingToType(wasteReports) {
        var typeArray = this.state.typeCounts
        typeArray.map(type => {
            type.count = 0
        })
        wasteReports.map(report => {
            report.wasteType.map(async type => {
                this.typeCountAdder(type)
            })
        })
    }

    onMarkerClick = async (props, marker, e) => {

        await this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            repId: marker.name._id,
            repImage: "https://api.kollektapp.org/" + marker.name.images[0].slice(10),
            repInside: ShowKonteynerNameAccordingToType(marker.name.wasteType),
            repDate: dateConvertion(new Date(Number(marker.name.createdAt))),
            wasteStatus: marker.name.waste_status
        });
        console.log(marker)
    }



    render() {

        return (

            <div class="page-wrapper">
                <div class="container-fluid">
                    <div className="column">

                        <div className="card">
                            <div className="card-body">
                            <div style={{ display: 'flex' }}>
                                <Select 
                                    styles={{ input: styles => ({ ...styles, width: 50 }) }}
                                    value={{value: this.state.limit, label: this.state.limit}} 
                                    onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                                    isMulti={false} options={limitOptions} placeholder="50"
                                />
                                <button class="btn btn-primary" disabled={this.state.disableLoadMore} onClick={this.loadMore}>{this.state.limit} adet daha yükle</button>
                            </div>
                                <div class="row col-md-12">
                                    <div className="col-md-6 btn-group">
                                        <div className="col-md-12" style={{ marginTop: "1%" }}>
                                            <button class="btn btn-primary col-12" style={this.state.yillik ? { backgroundColor: "#fd6a3f" } : {}} type="button" onClick={async () => await this.setState({ yillik: true, aylik: false })}>Yıllık Rapor</button>
                                            <button class="btn btn-primary col-12" style={this.state.aylik ? { backgroundColor: "#fd6a3f" } : {}} type="button" onClick={async () => await this.setState({ yillik: false, aylik: true })}>Aylık Rapor</button>
                                        </div>


                                    </div>
                                    


                                    <div className="col-md-6">
                                        <div class="btn-group" style={{ marginBottom: "1%" }}>
                                            <div className="column">
                                                <div className="row-12">
                                                    <select class="form-control-lg " onChange={async (e) => { await this.setState({ yearValue: e.target.value }) }}>
                                                        {this.state.yillar.map(yil => {
                                                            return (
                                                                <option value={yil}>{yil}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {this.state.aylik &&
                                                        <select class="form-control-lg " onChange={async (e) => { await this.setState({ monthValue: e.target.value }); console.log(this.state.monthValue) }}>



                                                            {this.state.aylar.map(ay => {
                                                                return (
                                                                    <option value={ay}>{this.state.aylarText[ay]}</option>
                                                                )
                                                            })}


                                                        </select>
                                                    }

                                                </div>
                                                {this.state.dataCame &&
                                                    <button class="btn btn-primary" type="button" style={{ marginTop: "4%" }} onClick={async () => { this.setFilteredWasteReports() }}>Raporu Görüntüle</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div style={{ height: '80vh', width: '90%' }}>
                                {this.state.startShowing && this.state.dataCame && this.state.filteredWasteReports.length > 0 && this.state.mapDataCame &&
                                    <Map

                                        google={this.props.google}
                                        zoom={17}
                                        initialCenter={{
                                            lat: this.state.mapLatLong.latitude,
                                            lng: this.state.mapLatLong.longitude
                                        }}
                                    >
                                        {
                                            this.state.filteredWasteReports.map(rep => {
                                                return (
                                                    <Marker
                                                        onClick={this.onMarkerClick}
                                                        position={{ lat: rep.latitude, lng: rep.longitude }}
                                                        name={rep}
                                                        icon={{
                                                            url: reportIndicator,
                                                            scaledSize: { width: 16, height: 16 }
                                                        }}
                                                    />
                                                )
                                            }
                                            )
                                        }
                                        <InfoWindow
                                            marker={this.state.activeMarker}
                                            visible={this.state.showingInfoWindow}>
                                            <div>
                                                <h5><b>id</b> {this.state.repId}</h5>
                                                <p><b>Neler Atılmış:</b> {this.state.repInside}</p>
                                                <p><b>Durum:</b> {this.state.wasteStatus}</p>
                                                <p><b>Tarih:</b> {this.state.repDate}</p>
                                                <p><b>Resim:</b> <img style={{ width: 100, height: 100 }} src={this.state.repImage}></img></p>
                                            </div>
                                        </InfoWindow>
                                    </Map>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        )
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyAdA0AjaFE0dcPMMqv9dRT06Pb1_A9xSS4")
})(WasteReportPlace)
