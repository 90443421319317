exports.dateConvertion = (date) => {
        var dateToReturn = ""
        var day = date.getDate().toString()
        var month = date.getMonth() + 1
        var hour = date.getHours().toString()
        var min = date.getMinutes().toString() 
       
        if(dateZeroHelper(day)){  
                dateToReturn = "0"+dateToReturn
        }
        if(dateZeroHelper(month.toString())){           
                month = "0"+month
        }
        if(dateZeroHelper(hour)){           
                hour = "0"+hour
        }
        if(dateZeroHelper(min)){           
                min = "0"+min
        }
        dateToReturn += day + "-"
        dateToReturn += month.toString() + "-"
        dateToReturn += date.getFullYear().toString() + " "
        dateToReturn += hour + ":"
        dateToReturn += min 
        return dateToReturn
}

exports.dateConvertionForSort = (date) => {
        var dateToReturn = ""
        var month = date.getMonth() + 1
        var day =  date.getDate().toString()
        var hour = date.getHours().toString()
        var min = date.getMinutes().toString() 
        if(dateZeroHelper(day)){  
                day = "0"+day
        }
        if(dateZeroHelper(month.toString())){           
                month = "0"+month
        }
        if(dateZeroHelper(hour)){           
                hour = "0"+hour
        }
        if(dateZeroHelper(min)){           
                min = "0"+min
        }
        dateToReturn += date.getFullYear().toString() + "-"
        dateToReturn += month.toString() + "-"       
        dateToReturn += day + " "
        dateToReturn += hour + ":"
        dateToReturn += min 

        return dateToReturn
}

let dateZeroHelper = (val) => {
        if(val === "1" || val === "2" || val === "3" || val === "4" ||
        val === "5" || val === "6" || val === "7" || val === "8" || 
        val === "9"){  
                return true
        }
        return false
}

exports.notificationExplanation = (explanation) => {
        if(explanation === "Hatalı"){
            return "Hatalı bildirim"
        }else if(explanation === "Yetki Dışı"){
            return "Yetki alanı dışı"
        }else if(explanation === "Bulunamadı"){
            return "Atık yerinde değil"
        }else if(explanation === "Yanıltıcı"){
                return "Yanıltıcı bildirim"
        }else if(explanation === "Çözüm Arayışı"){
                return "Erişilemeyen bölge"
        }else if(explanation === "Farklı Kurum"){
                return "Diğer kurum yetki alanı"
        }else if(explanation === "Farklı Bölge"){
                return "Özel mülkiyet"
        }else if(explanation === "Konteyner-Toplandı"){
                return "Konteyner eklendi"
        }else if(explanation === "Mıntıka"){
                return "Mıntıka yapıldı"
        }
}