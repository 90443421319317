export const nelerAtilirOptions = [
    { value: 1, label: 'Pet/Plastik' },
    { value: 2, label: 'Cam' },
    { value: 3, label: 'Kağıt/Karton' },
    { value: 4, label: 'Teneke Kutu/Metal' },
    { value: 5, label: 'Mutfak/Yemek Artıkları' },
    { value: 6, label: 'İnşaat/Moloz' },
    { value: 7, label: 'Bebek Bezi' },
    { value: 8, label: 'Park Bahçe Atıkları' },
    { value: 9, label: 'Araba Lastiği' },
    { value: 10, label: 'Eski Ev Eşyaları' },
    { value: 11, label: 'Cam Kırıkları' },
    { value: 12, label: 'Tıbbi Atık' },
    { value: 13, label: 'Elektronik Atık' },
    { value: 14, label: 'Bitkisel Atık Yağ' },
    { value: 15, label: 'tekstil Atıkları' },
    { value: 16, label: 'Meyve Sebze Artıkları' },
    { value: 17, label: 'Pil' },
    { value: 18, label: 'Atık İlaç' },
    { value: 19, label: 'Akü/Elektrikli Araç Bataryası' },
    { value: 20, label: 'Atık Motor Yağı' },
]

export const bildirimStatusOptions = [
    { value: "bekliyor", label: "Aktif Bildirimler" },
    { value: "İptal Edildi", label: "İptal Edildi" },
    { value: "Çözüm Aranıyor", label: "Çözüm Aranıyor" },
    { value: "Toplandı", label: "Geçmiş Bildirimler" },
]

export const atikToplanmaDurumuOptions = [
    { value: "Islem", label: "Bildirim İşleme Alındı" },
    { value: "Toplandı", label: "Bildirilen Atık Belediye Ekiplerince Toplanmıştır" },
    { value: "Konteyner-Toplandı", label: "Bildirilen atık toplanmış ve konteyner eklenmiştir" },
    { value: "Mıntıka", label: "Bölgede Belediyemiz tarafından mıntıka temizliği yapılmıştır" },
    { value: "Hatalı", label: "Hatalı/amaç dışı bildirim" },
    { value: "Yetki Dışı", label: "Raporlanan atık Belediyemizin yetki alanı dışında kalmaktadır" },
    { value: "Bulunamadı", label: "Raporlanan atık belirtilen konumda bulunamadı" },
    { value: "Yanıltıcı", label: "Bildiriminiz Belediye personelini yanıltıcı unsur içerdiği için iptal edilmiştir" },
    { value: "Çözüm Arayışı", label: "Raporlanan atık Belediyemizin mevcut erişim imkanları dahilinde alınamamaktadır" },
    { value: "Farklı Kurum", label: "Raporlanan konum farklı bir kurumun (DSİ, Büyükşehir Belediyesi, vs.) yetki alanındadır" },
    { value: "Farklı Bölge", label: "Raporlanan atık bir özel mülkiyet ya da işletmenin sorumluluk alanında" },
    { value: "Farklı Belediye", label: "Bildiriminiz üye belediyelerin yetki alanı dışında" },
]

export const isSelfOption = [
    { value: false, label: "Belediye'ye bildirimler" },
    { value: true, label: "Kendim atacağım bildirimleri" }
]