import React,{Component} from 'react'
import AuthContext from '../context/auth-context'
import {Redirect} from 'react-router-dom';

import {connect} from 'react-redux'

import{authUser,logout} from '../store/actions'

import {call} from '../services/api'

class AuthPage extends Component{

    constructor(props)
    {
        super(props);
        this.EmailEl  = React.createRef();
        this.PasswordEl = React.createRef();
        this.errorMessage = ""
    }
    

    static contextType = AuthContext;

    state={
        isSignin :false 
    }

    componentWillMount(){
        if(this.props.auth.isAuthenticated)
        {
            this.props.history.replace('/index')
        }
    }

    submitHandler = (event)=>{
        event.preventDefault()
        const email = this.EmailEl.current.value
        const password = this.PasswordEl.current.value

        if(email.trim().length === 0 || password.trim().length === 0)
        {
            return;
        }

        const requestBody = {
            query: `
                query{
                    login(email:"${email}",password:"${password}")
                    {
                    _id
                    token
                    }
                }
            `
        }
        
        call(requestBody).then(response=>{
            console.log(response)
            if(!response.errors)
            {
                this.props.authUser(response.data.login.token,response.data.login._id)
                this.props.history.replace("/index#login");
            }
            else{
                this.setState({errorMessage:"Kullanıcı adı yada şifre yanlış."})
            }
        })
    }

    render(){
     
        if(this.state.isSignin)
        {
            return <Redirect to="/index"/>
        }
        return (
            <React.Fragment>
                <section id="wrapper" class="login-register login-sidebar" style ={ { backgroundImage: "url(assets/images/login_bg.png)" } }>
                <div class="login-box card">

                    <div class="card-body text-center">

                    <form class="form-horizontal form-material " id="loginform" onSubmit={this.submitHandler}>
                    <a href="javascript:void(0)" class="db"><br/></a>
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" ref={this.EmailEl} required="" placeholder="E-Posta Adresi" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" required="" ref={this.PasswordEl} placeholder="Şifre" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="d-flex no-block align-items-center">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                    <label class="custom-control-label" for="customCheck1">Beni hatırla</label>
                                </div> 
                               
                            </div>   
                        </div>
                    </div>

                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button  class="btn btn-info btn-lg btn-block btn-rounded" type="submit">Giriş Yap</button>
                        </div>
                        <h5 className="text-danger">{this.state.errorMessage}</h5>

                    </div>

                </form>
                    </div>
                </div>
              </section>
             </React.Fragment>
        )
    }
}

export default connect((store)=>({auth:store.auth}),{authUser,logout})(AuthPage)