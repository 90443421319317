import { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

import {logout} from '../store/actions/';

class LogoutPage extends Component {

  componentWillMount() {
    this.props.logout()
    this.props.history.replace("/auth");
  }

  render() {
    return null
  }
}

export default connect(()=>{},{logout})(LogoutPage)