import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import Select from 'react-select';
import { createDataTable } from '../helpers/dataTable';
import { limitOptions } from './activeWasteReports'
import {dateConvertion, dateConvertionForSort, notificationExplanation} from '../helpers/dateHelpers'
import {call} from '../../services/api'
import { ShowKonteynerNameAccordingToType } from '../helpers/markerHelpers'

class PastWasteReports extends Component{

    state = {
        wasteReports: [],
        konteyners: [],
        dataCame: false,
        limit: 50,
        skip: 0
    }

    componentWillMount(){
        this.loadMore();
    }

    loadMore = async () => {
        const { skip, limit, wasteReports } = this.state;
        await this.setState({ dataCame: false })

        const requestBody = {
            query: `
                query{
                    getPastWasteReports(skip: ${skip}, limit: ${limit})
                    {
                        _id
                        images
                        privacy
                        wasteType
                        latitude
                        longitude
                        createdAt
                        isSelf
                        waste_status
                        statusExplanation
                        createdAt
                        creator {email}
                    }
                }
            `
        }

        call(requestBody).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            } else {
                let wasteRepr = []
                resData.data.getPastWasteReports.map(rep => {
                    if (rep.isSelf === false) {
                        wasteRepr.push(rep)
                    }
                })
                const newSkip = skip + resData.data.getPastWasteReports.length;
                const newWasteReports = [...wasteReports, ...resData.data.getPastWasteReports];
                await this.setState({
                    wasteReports: newWasteReports,
                    dataCame: true,
                    skip: newSkip,
                    disableLoadMore: resData.data.getPastWasteReports.length === 0,
                })
                createDataTable();
            }
        }).catch(err => { console.log(err); this.setState({ dataCame: true }) })
    }

    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">


            <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Geçmiş Atık Bildirimleri</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                <li class="breadcrumb-item active">Geçmiş Atık Bildirimleri</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Select 
                        styles={{ input: styles => ({ ...styles, width: 50 }) }}
                        value={{value: this.state.limit, label: this.state.limit}} 
                        onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                        isMulti={false} options={limitOptions} placeholder="50"
                    />
                    <button class="btn btn-primary" disabled={this.state.disableLoadMore} onClick={this.loadMore}>{this.state.limit} adet daha yükle</button>
                </div>
                <div className="row">
                    <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">Geçmiş Atık Bildirimleri</h4>
                        <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                        <div className="table-responsive m-t-40">
                            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                            <thead>
                                <tr>
                                <th>Bildirim Numarası</th>
                                {/*<th>Kullanıcı</th>*/}
                                <th>Durum</th>
                                <th>Durum Açıklaması</th>
                                <th>Atık Tipleri</th>
                                <th>Bildirim Tarihi</th>
                                <th>Görüntüle</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Bildirim Numarası</th>
                                 {/*<th>Kullanıcı</th>*/}
                                <th>Durum</th>
                                <th>Durum Açıklaması</th>
                                <th>Atık Tipleri</th>
                                <th>Bildirim Tarihi</th>
                                <th>Görüntüle</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {this.state.wasteReports.map(rep => {
                                    return(
                                        <tr>
                                        <td>{rep._id}</td>
                                        {/*rep.creator !== null ? <td>{rep.creator.email}</td> : <td>kullanıcı bilgisi girilmemiş</td>*/} 
                                        {rep.waste_status === "bekliyor" &&
                                        <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#ffbf3e"}}>{rep.waste_status}</span> </td>
                                        }
                                        {rep.waste_status === "Toplandı" &&
                                        <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#7abe45"}}>{rep.waste_status}</span> </td>
                                        }
                                        {rep.waste_status === "İptal Edildi" &&
                                        <td><span class="badge badge-success badge-pill" style={{backgroundColor:"#fc4b6c"}}>{rep.waste_status}</span> </td>
                                        }
                                        {rep.statusExplanation !== null ? <td>{notificationExplanation(rep.statusExplanation)}</td> : <td>Girilmemiş</td>}
                                        {<td>{ShowKonteynerNameAccordingToType(rep.wasteType)}</td>}
                                        <td><span style={{display:"none"}}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</td>
                                        <td><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{pathname:`/wasteReportDetail?_id=${rep._id}`, aboutProps:{report:rep}}}>Görüntüle</Link></span></td>
                                        </tr>
                                      )              
                                })}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
               
         
        )
    }
}

export default PastWasteReports