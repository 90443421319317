import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'
import { call } from '../../services/api';


class UserReporting extends Component{

    

    constructor(props) {
        super(props);
        this.state = {
            wasteReports: [],
            konteyners: [],
            dataCame: false,
            data2Came: false,
            typeCounts: [{count: 0, isim:"Pet Şişe"}],
            //type counts
            year : 0,
            month: 0, 
            ex1: "example23",
            ex2: "example24",
            ex3: "example25",
            ex4: "example26"
        }
        
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.loadStats = this.loadStats.bind(this)
      }

    async componentWillMount(){

         const year = new Date().getFullYear()
         const month = new Date().getMonth() +1

         this.loadStats(year,month)
        
    }

    componentDidUpdate() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `$('#${this.state.ex1}').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                        }
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        const d = document.createElement('script');
        d.type = 'text/javascript';
        d.async = true;
        d.innerHTML = `$('#${this.state.ex2}').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                        }
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        const f = document.createElement('script');
        f.type = 'text/javascript';
        f.async = true;
        f.innerHTML = `$('#${this.state.ex3}').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                        }
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        const g = document.createElement('script');
        g.type = 'text/javascript';
        g.async = true;
        g.innerHTML = `$('#${this.state.ex4}').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        "language": {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json"
                        }
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        if(this.state.dataCame)
        {
            document.body.appendChild(s);
            document.body.appendChild(d);
            document.body.appendChild(f);
            document.body.appendChild(g);
        }
    }

    handleChange(event) {
        this.setState({ex1:"a", ex2:"b", ex3:"c",ex4:"d",year: event.target.value});
      }

    handleChange2(event) {
        this.setState({ex1:"a", ex2:"b", ex3:"c",ex4:"d",month: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.loadStats(this.state.year,this.state.month)
    }

    
    loadStats(year, month) {

        const requestBody = {
            query: `
                query{
                    getUserStats(year:${year},month:${month}){
                    countryStats{
                        _id
                        count
                    }
                    genderStats{
                        _id
                        count
                    }
                    ageStats{
                        _id
                        count
                    }
                    userWasteReports{
                        _id
                        count
                    }
                    }
                }
            `
        }
        const requestBody2 = {
            query: `
                query{
                    getLeaderBoardForBelediye{
                        _id
                        point
                        name
                    }
                }
            `
        }
        call(requestBody2).then(async resData => {
            await this.setState({userWaste : resData.data.getLeaderBoardForBelediye, data2Came: true})
            
        }).catch(err => console.log(err))
        
        call(requestBody).then(async resData=>{
                if(resData.errors) 
                {
                    console.log("unauthenticated")
                }
                console.log(resData)

                await this.setState({country : resData.data.getUserStats.countryStats})
                await this.setState({gender : resData.data.getUserStats.genderStats})
                await this.setState({age : resData.data.getUserStats.ageStats})
                await this.setState({dataCame : true})
                
            }).catch(err=>{console.log(err)})
    }

    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }


    render(){
        if(!this.state.dataCame || !this.state.data2Came){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">

                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                    <h4 className="text-themecolor">Kullanıcı Raporları</h4>
                    </div>
                    <div className="col-md-7 align-self-center text-right">
                    <div className="d-flex justify-content-end align-items-center">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Kullanıcı Raporları</li>
                        </ol>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                    <div className="row">

                    <div className="col-6">

                    <div className="card">
                        <div className="card-body">
                        <div>
                        <h4 className="card-title">Kullanıcı - Ülke Raporu</h4>
                        <h6 className="card-subtitle">Kayıtlı kullanıcıların ülkelere göre sayısı</h6>
                        <div className="table-responsive">
                        <table id="example23" className="table table-hover">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Ülke</th>
                                <th>Toplam Kullanıcı</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.country.map((data,index) => {
                                    return(
                                        <tr>
                                        <td>{index+1}</td>
                                        <td>{data._id === null ? "Belirlenmemiş" : data._id}</td>
                                        <td>{data.count}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-6">

                <div className="card">
                    <div className="card-body">
                    <div>
                    <h4 className="card-title">Kullanıcı - Cinsiyet Raporu</h4>
                    <h6 className="card-subtitle">Kayıtlı kullanıcıların cinsiyete göre sayısı</h6>
                    <div className="table-responsive">
                    <table id="example24" className="table table-hover">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Cinsiyet</th>
                            <th>Toplam Kullanıcı</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.gender.map((data,index) => {
                                return(
                                    <tr>
                                    <td>{index+1}</td>
                                    {data._id === "2" && <td>Erkek</td>}
                                    {data._id === null && <td>Belirlenmemiş</td>}
                                    {data._id === "1" && <td>Kadın</td>}
                                    {data._id === "3" && <td>Belirtmek İstemiyor</td>}
                                    <td>{data.count}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </div>


            <div className="row">
                    <div className="col-12">
                    <div className="row">

                    <div className="col-6">

                    <div className="card">
                        <div className="card-body">
                        <div>
                        <h4 className="card-title">Puan Tablosu</h4>
                        <div  >
                        {/* <form className="row" onSubmit={this.handleSubmit}>
                            <div className="col-md-4">
                            <select className="browser-default custom-select custom-select-lg mb-3" value={this.state.year} onChange={this.handleChange}>
                            <option value="0" selected>Yıl</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            </select>
                            </div>
                            <div className="col-md-4">
                            <select className="browser-default custom-select custom-select-lg mb-3" value={this.state.month} onChange={this.handleChange2}>
                            <option value="0">Ay</option>
                            <option value="1">Ocak</option>
                            <option value="2">Şubat</option>
                            <option value="3">Mart</option>
                            <option value="4">Nisan</option>
                            <option value="5">Mayıs</option>
                            <option value="6">Haziran</option>
                            <option value="7">Temmuz</option>
                            <option value="8">Ağustos</option>
                            <option value="9">Eylül</option>
                            <option value="10">Ekim</option>
                            <option value="11">Kasım</option>
                            <option value="12">Aralık</option>
                            </select>
                            </div>
                            <div className="col-md-4">
                            <button type="submit" class="btn btn-primary btn-lg">Seçili Tarihi Yükle</button>

                            </div>
                        </form> */}
                        </div>
                        <div className="table-responsive">
                        <table id="example25" className="table table-hover">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>İsim</th>
                                <th>Puan</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.userWaste.map((data,index) => {
                                    return(
                                        <tr>
                                        <td>{index+1}</td>
                                        <td>{data.name}</td>
                                        <td>{data.point}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-6">

                <div className="card">
                    <div className="card-body">
                    <div>
                    <h4 className="card-title">Kullanıcı - Yaş Raporu</h4>
                    <h6 className="card-subtitle">Kayıtlı kullanıcıların yaşa göre sayısı</h6>
                    <div className="table-responsive">
                    <table id="example26" className="table table-hover">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Yaş</th>
                            <th>Toplam Kullanıcı</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.age.map((data,index) => {
                                return(
                                    <tr>
                                    <td>{index+1}</td>
                                    <td>{data._id === null ? "Belirlenmemiş" : data._id}</td>
                                    <td>{data.count}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </div>



        </div>
        </div>      
         
        )
    }
}

export default UserReporting